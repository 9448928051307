import { graphql } from 'gatsby';
import React from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import { Url } from 'url';
import styles from './social-share.module.scss';

interface SocialShareProps {
  facebook?: string;
  linkedin?: string,
  location: Url;
  title: string;
  twitter?: string;
}

export const SocialShare: React.FC<SocialShareProps> = ({
                                                          facebook,
                                                          linkedin,
                                                          location,
                                                          title,
                                                          twitter,
                                                        }) => {
  // style the icon fill colour
  const iconFillColour = 'grey';

  // style the icons here
  const bgStyle: React.CSSProperties = { fill: 'white', stroke: iconFillColour };

  // specify default size for all icons
  const iconSize = 32;


  const { href } = location;

  return (
    <div className={styles.area}>
      <h5 className={styles.header}>Share this...</h5>
      <div className={styles.button__container}>
        {facebook && (<div className="social-share-facebook">
          <FacebookShareButton
            url={href}
            quote={title}
            className="social-share-facebook-button"
          >
            <FacebookIcon size={iconSize} round iconFillColor={iconFillColour} bgStyle={bgStyle}/>
          </FacebookShareButton>
        </div>)}

        {twitter && (<div className="social-share-twitter">
          <TwitterShareButton
            url={href}
            via={twitter}
            className="social-share-twitter-button"
          >
            <TwitterIcon size={iconSize} round iconFillColor={iconFillColour} bgStyle={bgStyle}/>
          </TwitterShareButton>
        </div>)}

        {linkedin && (<div className="social-share-linkedin">
          <LinkedinShareButton
            url={href}
            // summary={title}
            // source={linkedin}
            className="social-share-linkedin-button"
          >
            <LinkedinIcon size={iconSize} round iconFillColor={iconFillColour} bgStyle={bgStyle}/>
          </LinkedinShareButton>
        </div>)}
      </div>
    </div>
  );
};

export const fragment = graphql`
  fragment SocialShareFrag on ContentfulSocialShare {
    facebook
    linkedin
    name
    twitter
  }
`;



import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import {
  Anchor,
  Apply,
  Biographies,
  CardCarousel,
  Cta,
  DetailCards,
  Divider,
  Footer,
  FooterBlock,
  GDPRBanner,
  Header,
  Hero,
  ImageSlider,
  LogoBanner,
  MarketingModule,
  NotificationMessage,
  PartnerLogos,
  RichTextImage,
  SummaryModule,
  TestimonialsModule,
  TwoColumn,
} from '../components';
import { FooterBlockContentfulProps } from '../components/footer-block';
import { HeaderContentfulProps } from '../components/header';
import {
  FooterContentfulProps
} from '../components/footer';

import '../styles/globals.scss';
import { SocialShare } from '../components/social-share';
import { TestimonialsList } from '../components/testimonials-list';
import { UseSiteMetadata } from '../utils/use-site-meta-data';
import styles from './page.module.scss';
import { GdprBannerProps } from '../components/gdpr-banner';
import { NotificationMessageProps } from '../components/notification-message';

interface PageProps {
  data: {
    contentfulPage: {
      header: HeaderContentfulProps;
      modules: {
        __typename: string;
        id: string;
      }[];
      footer: FooterContentfulProps;
      footerBlock: FooterBlockContentfulProps;
      slug: string;
      title: string;
      metaTags: {
        id: string;
        key: string;
        value: {
          value: string;
        };
      }[];
      notificationMessage: NotificationMessageProps;
      enableSocialShare: boolean;
    };
    contentfulGdprCookieBanner: GdprBannerProps;
  };
  pageContext: string;
  location: string;
}

const moduleTypeMap: { [x: string]: React.FC } = {
  ContentfulApply: Apply,
  ContentfulAnchor: Anchor,
  ContentfulBiographies: Biographies,
  ContentfulDetailCardsModule: DetailCards,
  ContentfulDivider: Divider,
  ContentfulExpandableCardCarousel: CardCarousel,
  ContentfulHero: Hero,
  ContentfulImageSlider: ImageSlider,
  ContentfulLogoBanner: LogoBanner,
  ContentfulMarketingModule: MarketingModule,
  ContentfulLink: Cta,
  ContentfulPartnerLogos: PartnerLogos,
  ContentfulSummariesModule: SummaryModule,
  ContentfulRichTextWithImage: RichTextImage,
  ContentfulSocialShare: SocialShare,
  ContentfulTestimonialsList: TestimonialsList,
  ContentfulTestimonialsModule: TestimonialsModule,
  ContentfulTwoColumn: TwoColumn,
};

const Page: React.FC<PageProps> = ({
                                     data: {
                                       contentfulPage: page,
                                       contentfulGdprCookieBanner,
                                     },
                                     location,
                                   }: PageProps) => {
  if (page) {
    // handle the page slug for the home page properly
    const pageSlug = page.slug === '/' ? 'home' : page.slug;
    const { pageConfig } = UseSiteMetadata();

    // extract page config the specific page
    const config = pageConfig?.[pageSlug] || {};

    return (
      <div className={styles.page}>
        <Helmet>
          <title>{page.title || 'Reset Health'}</title>
          {page.metaTags &&
          page.metaTags.map(({ id, key, value }) => (
            <meta key={id} name={key} content={value?.value}/>
          ))}
        </Helmet>
        {page.notificationMessage && <NotificationMessage {...page.notificationMessage} />}
        {page.header && <Header {...page.header} location={location}/>}
        {page.modules?.map(
          ({ __typename: moduleType, id, ...moduleParams }, i) => {
            const Module = moduleTypeMap[moduleType];
            // need a way to choose the correct form to populate
            Object.assign(moduleParams, config, { location });
            return Module ? (
              <Module
                key={`${id}-${i}`}
                {...moduleParams}
              />
            ) : (
              <div key={`${id}-${i}`}>{`unknown module ${moduleType}`}</div>
            );
          },
        )}
        {page.footer && (
          <Footer
            {...page.footer}
          />
        )}
        {page.footerBlock && (
          <FooterBlock
            {...page.footerBlock}
          />
        )}
        {contentfulGdprCookieBanner && (
          <GDPRBanner {...contentfulGdprCookieBanner} />
        )}
      </div>
    );
  }
  return null;
};

export default Page;

export const pageQuery = graphql`
  query page($id: String!) {
    ...GDPRFrag
    contentfulPage(id: { eq: $id }) {
      header {
        ...HeaderFrag
      }
      footer {
        ...FooterFrag
      }
      title
      metaTags {
        id
        key
        value {
          value
        }
      }
      notificationMessage {
        ...NotificationFrag
      }
      enableSocialShare
      modules {
        __typename
        ...ApplyFrag
        ...AnchorFrag
        ...BiographyFrag
        ...CtaFrag
        ...CardCarousel
        ...DetailCardsFrag
        ...HeroFrag
        ...ImageSliderFrag
        ...LogoBannerFrag
        ...MarketingFrag
        ...PartnerLogoFrag
        ...SummariesFragment
        ...RichTextImageFrag
        ...SocialShareFrag
        ...TestimonialsListFrag
        ...TestimonialsModuleFrag
        ...TwoColumnFragment
        ... on Node {
          id
        }
      }
      slug
    }
  }
`;
